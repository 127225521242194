import React, { useMemo, useState } from "react";
import "./HoverInfoSection.scss";
import PageHeading from "@common/PageHeading/PageHeading";
import { TABLET_MAX_WIDTH } from "@constants";
import useWindowDimensions from "@hooks/useWindowDimensions";
import { ReactSVG } from "react-svg";
import HoverInfoSectionDescription from "./HoverInfoSectionDescription/HoverInfoSectionDescription";

const HoverInfoSection = ({
    valueImages,
    descriptions,
    heading,
    headingDescription,
    topOffset,
    leftOffset,
}) => {
    const { width } = useWindowDimensions();
    const isDesktop = useMemo(() => width > TABLET_MAX_WIDTH, [width]);

    const [isHovered, setIsHovered] = useState(false);
    const [imageIndex, setImageIndex] = useState(null);

    const setStyles = (event, index, isHovered) => {
        setIsHovered(isHovered);
        setImageIndex(index);
    };

    return (
        <div className="values-section">
            <div
                className="values-section__heading"
                style={
                    headingDescription
                        ? {
                              marginBottom: "2.5rem",
                          }
                        : null
                }
            >
                <PageHeading
                    heading={heading}
                    mainParagraph={headingDescription}
                />
            </div>
            {isDesktop ? (
                <div className="values-section__container">
                    <div className="values-section__values-container">
                        {valueImages.map((value, index) => (
                            <div
                                className="values-section__image-container-wrapper"
                                key={index}
                                style={
                                    topOffset && index !== 0
                                        ? {
                                              marginTop: topOffset,
                                          }
                                        : null
                                }
                            >
                                <div
                                    className={`values-section__image-container values-section__image-container-${index}`}
                                    onMouseEnter={event =>
                                        setStyles(event, index, true)
                                    }
                                    onMouseLeave={event =>
                                        setStyles(event, index, false)
                                    }
                                >
                                    <ReactSVG
                                        src={value.svgPath}
                                        role="img"
                                        aria-label={value.alt}
                                        className="values-section__image"
                                        style={
                                            leftOffset
                                                ? {
                                                      width: leftOffset,
                                                  }
                                                : null
                                        }
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                    <HoverInfoSectionDescription
                        descriptions={descriptions}
                        isHovered={isHovered}
                        imageIndex={imageIndex}
                    />
                </div>
            ) : (
                <div className="values-section__container">
                    {descriptions.map((description, index) => (
                        <div
                            className="values-section__value-container"
                            key={index}
                        >
                            <ReactSVG
                                src={valueImages[index].svgPath}
                                className="values-section__image"
                                role="img"
                                aria-label={valueImages[index].alt}
                            />
                            <p className="values-section__value-description">
                                {description}
                            </p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default HoverInfoSection;
