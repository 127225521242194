import React from "react";
import "./HoverInfoSectionDescription.scss";

const HoverInfoSectionDescription = ({
    descriptions,
    isHovered,
    imageIndex,
}) => (
    <div className="values-description">
        {descriptions.map((description, index) => (
            <p
                className="values-description__description"
                key={index}
                style={{
                    color:
                        isHovered && imageIndex === index
                            ? "white"
                            : "var(--main-font-color)",
                }}
            >
                {description}
            </p>
        ))}
    </div>
);

export default HoverInfoSectionDescription;
